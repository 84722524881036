<template>
  <v-container id="routes" fluid tag="section">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <div class="d-flex align-baseline">
              <v-overflow-btn
                v-model="method"
                color="primary"
                :items="allowedMethods"
              ></v-overflow-btn>
              <v-text-field v-model="uri" class="px-2"></v-text-field>
              <v-btn color="primary" @click="sendRequest">Send</v-btn>
            </div>
            <v-textarea
              v-model="testParams"
              style="font-family: monospace"
              filled
              label="Parameters to send (JSON)"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="6">
            <v-progress-linear
              v-if="sending"
              color="light-blue"
              height="10"
              indeterminate
              striped
            ></v-progress-linear>
            <v-alert v-if="testError" type="error">{{ testError }}</v-alert>
            <template v-if="testResponse">
              <v-textarea
                id="tocopy"
                v-model="testResponse"
                style="font-family: monospace"
                filled
                label="Server response (JSON)"
                readonly
              ></v-textarea>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="float-right"
                    style="right: 30px; top: -60px"
                    fab
                    color="cyan"
                    v-on="on"
                  >
                    <v-icon @click="copyText">mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>Copy to clipboard</span>
              </v-tooltip>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      testParams: "{\n\n}",
      testResponse: null,
      testError: null,
      allowedMethods: ["GET", "POST", "PUT", "PATCH", "DELETE", "HEAD"],
      uri: "/",
      method: "GET",
    };
  },

  mounted() {
    this.uri = "/" + this.$route.params.uri;
    this.method = this.$route.params.method;
  },

  methods: {
    sendRequest() {
      this.testError = null;
      this.testResponse = null;
      try {
        const params = JSON.parse(this.testParams);
        let apiCall = this.get(this.uri, params, this.method);
        switch (this.method) {
          case "GET":
          case "HEAD":
            apiCall = this.get(this.uri, params, this.method);
            break;
          case "DELETE":
            apiCall = this.delete(this.uri, params);
            break;
        }
        apiCall
          .then((data) => {
            this.testResponse = JSON.stringify(data, undefined, 2);
          })
          .catch((error) => {
            this.testError = error.message;
            this.testResponse = JSON.stringify(error, undefined, 2);
          });
      } catch (ex) {
        this.testError = ex.message;
      }
    },

    copyText() {
      var $body = document.getElementsByTagName("body")[0];
      var $tempInput = document.createElement("TEXTAREA");
      $body.appendChild($tempInput);
      $tempInput.value = this.testResponse;
      $tempInput.select();
      document.execCommand("copy");
      $body.removeChild($tempInput);
    },
  },
};
</script>

<style lang="scss">
.v-overflow-btn {
  max-width: 130px;
  .v-input__slot,
  .v-select__slot {
    border: none;
    background: #eee;
    border-radius: 4px;
  }
}
</style>
